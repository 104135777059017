import { CssBaseline, ThemeProvider } from "@mui/material";
import { Suspense, lazy } from "react";
import ErrorBoundary from "./components/ErrorBoundary";
import { Routes } from "./routes";
const MessageBox = lazy(() => import("./components/MessageBox"));

import { IdaasProvider } from "@entrust/idaas-auth-react";
import Layout from "./components/Layout/Layout";
import { useAuth } from "./hooks/useAuth";
export default function App() {
  const { loadTheme } = useAuth();

  return (
    <IdaasProvider
      issuerUrl="https://entrust-bank.us.trustedauth.com/api/oidc"
      clientId="1bc8e586-9bad-4399-b68e-eb1ecd94452a"
    >
      <ErrorBoundary>
        <Suspense fallback="loading...">
          <ThemeProvider theme={loadTheme()}>
            <CssBaseline enableColorScheme />
            <Layout>
              <Routes />
              <MessageBox />
            </Layout>
          </ThemeProvider>
        </Suspense>
      </ErrorBoundary>
    </IdaasProvider>
  );
}
