import { lazy, useEffect } from "react";
import { Route, Switch } from "wouter";
import { RequireAuth } from "../components/RequireAuth";
import { RequireNoAuth } from "../components/RequireNoAuth";
import { useAuth } from "../hooks/useAuth";
import { Paths } from "../paths";
import type { CARD_TYPE } from "../stores/UserStore";

const CardManagement = lazy(() => import("./CardManagement"));
const Home = lazy(() => import("./Home"));
const Login = lazy(() => import("./Login"));
const Logout = lazy(() => import("./Logout"));
const OidcLogin = lazy(() => import("./OidcLogin"));
const Register = lazy(() => import("./Register"));
const Settings = lazy(() => import("./Settings"));
const Profile = lazy(() => import("./Profile"));
const StudentHome = lazy(() => import("./StudentHome"));
const Algoan = lazy(() => import("./Algoan"));
const LoginPassword = lazy(() => import("./LoginPassword"));
const LoginDefault = lazy(() => import("./LoginDefault"));

export const Routes = () => {
  const { isLoggedIn, user, fetchProfileInfo, addUserInfo, idaas } = useAuth();
  useEffect(() => {
    if (idaas.isAuthenticated) {
      idaas.getUserInfo().then((userClaims) => {
        addUserInfo({
          firstName: userClaims?.given_name ?? "",
          lastName: userClaims?.family_name ?? "",
          userId: userClaims?.sub ?? "",
          email: userClaims?.email ?? "",
          tokens: [],
        });
      });
    }
  }, [idaas.isAuthenticated]);

  if (isLoggedIn() === null) {
    return null;
  }

  useEffect(() => {
    if (isLoggedIn() && user?.userId) {
      fetchProfileInfo(user.userId);
    }
  }, []);

  return (
    <Switch>
      <Route path="/account/:accountType">
        {(params) => (
          <RequireAuth>
            <CardManagement type={params.accountType as CARD_TYPE} />
          </RequireAuth>
        )}
      </Route>

      <Route path={Paths.home}>
        <RequireAuth>
          <Home />
        </RequireAuth>
      </Route>

      <Route path={Paths.logout}>
        <Logout />
      </Route>

      <Route path={Paths.login}>
        <RequireNoAuth>
          <Login />
        </RequireNoAuth>
      </Route>

      <Route path={Paths.oidcLogin}>
        <RequireNoAuth>
          <OidcLogin />
        </RequireNoAuth>
      </Route>

      <Route path={Paths.register}>
        <Register />
      </Route>

      <Route path={Paths.settings}>
        <Settings />
      </Route>

      <Route path={Paths.profile}>
        <RequireAuth>
          <Profile />
        </RequireAuth>
      </Route>

      <Route path={Paths.algoan}>
        <Algoan />
      </Route>

      <Route path={Paths.logindefault}>
        <RequireNoAuth>
          <LoginDefault />
        </RequireNoAuth>
      </Route>

      <Route path={Paths.loginpassword}>
        <RequireNoAuth>
          <LoginPassword />
        </RequireNoAuth>
      </Route>

      {/* STUDENT JOURNEY PATHS */}
      <Route path={Paths.studentHome}>
        <RequireAuth>
          <StudentHome />
        </RequireAuth>
      </Route>

      {/* Default route in a switch */}
      <Route>404: No such page!</Route>
    </Switch>
  );
};
